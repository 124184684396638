<template>
  <b-card
    no-body
    border-variant="dark"
    class="p-1 mb-0 reset-calls"
  >
    <b-row align-v="center">
      <b-col>
        <h3 class="mb-0">
          Calls
        </h3>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          size="sm"
          @click="
            create = true;
            showEditForm = false;
            showCalls = false;
            clearFields();
          "
        ><feather-icon
          icon="PhoneIcon"
          class="mr-25"
        />Log a Call</b-button>
      </b-col>
    </b-row>
    <hr class="dropdown-divider mt-1 mb-0">
    <VuePerfectScrollbar
      class="scroll-area"
      style="width: 104%; height: 400px"
      :settings="settings"
      @ps-scroll-y="scrollHandle"
    >
      <div class="pr-1">
        <b-form
          v-if="create"
          class="mt-1"
          @submit.prevent
        >
          <b-row>
            <!-- subject -->
            <b-col cols="12">
              <b-form-group
                label="Subject "
                label-for="h-subject "
              >
                <b-form-input
                  id="h-subject "
                  v-model="subject"
                  placeholder="Project Demo"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- call type -->
            <b-col cols="12">
              <b-form-group
                label="Call Type"
                label-for="h-call-type"
              >
                <b-form-input
                  id="h-call-type"
                  v-model="callType"
                  list="input-list"
                  placeholder="Call Type"
                  required
                />
                <b-form-datalist
                  id="input-list"
                  :options="options"
                />
              </b-form-group>
            </b-col>

            <!-- duration -->
            <b-col cols="12">
              <b-form-group
                label="Duration (Format h:m:s i.e 00:35:20 means 35 Minutes and 20 Sec)"
                label-for="d-duration"
              >
                <b-form-input
                  id="d-duration"
                  v-model="duration"
                  placeholder="00:35:20"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Assignee -->
            <b-col cols="12">
              <b-form-group
                label="Assignee"
                label-for="h-assignee"
              >
                <b-form-input
                  id="h-assignee"
                  v-model="assignee"
                  placeholder="Assignee"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- checkbox -->
            <!--<b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              id="checkbox-9"
              v-model="checked"
              name="checkbox-9"
              value="Remember_me"
            >
              Schedule call
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col v-if="checked" cols="12">
          <b-form-group>
            <b-form-datepicker
              placeholder="MM-DD-YYYY"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
          <b-form-group
            label="Reminder (Minutes before)"
            label-for="h-reminder"
          >
            <b-form-input id="h-reminder" placeholder="5" />
          </b-form-group>
        </b-col>-->

            <!-- Description -->
            <!--<b-col cols="12">
          <b-form-group label="Description" label-for="h-description">
            <b-form-input id="h-description" placeholder="Type here.." />
          </b-form-group>
        </b-col>-->

            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="addCall()"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="
                  create = false;
                  clearFields();
                  showCalls = true;
                "
              >
                Close
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-form
          v-if="showEditForm"
          class="mt-1"
          @submit.prevent
        >
          <b-row>
            <!-- subject -->
            <b-col cols="12">
              <b-form-group
                label="Subject "
                label-for="h-subject "
              >
                <b-form-input
                  id="h-subject "
                  v-model="subject"
                  placeholder="Project Demo"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- call type -->
            <b-col cols="12">
              <b-form-group
                label="Call Type"
                label-for="h-call-type"
              >
                <b-form-input
                  id="h-call-type"
                  v-model="callType"
                  list="input-list"
                  placeholder="Call Type"
                  required
                />
                <b-form-datalist
                  id="input-list"
                  :options="options"
                />
              </b-form-group>
            </b-col>

            <!-- duration -->
            <b-col cols="12">
              <b-form-group
                label="Duration (Format h:m:s i.e 00:35:20 means 35 Minutes and 20 Sec)"
                label-for="d-duration"
              >
                <b-form-input
                  id="d-duration"
                  v-model="duration"
                  placeholder="00:35:20"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Assignee -->
            <b-col cols="12">
              <b-form-group
                label="Assignee"
                label-for="h-assignee"
              >
                <b-form-input
                  id="h-assignee"
                  v-model="assignee"
                  placeholder="Assignee"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="editCall()"
              >
                Edit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="
                  showEditForm = false;
                  clearFields();
                  showCalls = true;
                "
              >
                Close
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <div
          v-if="counter === 0"
          class="text-center mt-1"
        >
          There is no calls
        </div>
        <div
          v-for="(call, index) in calls"
          v-show="showCalls"
          :key="index"
          ref="calls"
        >
          <b-card
            no-body
            class="mb-0 mt-1 p-50"
            border-variant="dark"
          >
            <b-row
              no-gutters
              align-v="center"
            >
              <b-col>
                <div class="d-flex">
                  <feather-icon
                    icon="BarChartIcon"
                    class="mr-25"
                    size="20"
                  />
                  <span style="white-space: nowrap">{{ call.duration }}</span>
                  &nbsp;-&nbsp;
                  <span style="white-space: nowrap">{{ call.assignee }}</span>
                </div>
                <span>{{ call.subject }}</span>
                <br>
                Call Type:
                <span>{{ call.callType }}</span>
              </b-col>
              <!-- Remove Button -->
              <b-col
                class="text-right d-flex"
                style="justify-content: right; align-items: center"
              >
                <feather-icon
                  icon="EditIcon"
                  style="cursor: pointer"
                  size="18"
                  @click="
                    getItemData(index);
                    showEditForm = true;
                    create = false;
                    showCalls = false;
                  "
                />

                <feather-icon
                  icon="XIcon"
                  style="cursor: pointer; margin-left: 5px"
                  size="18"
                  @click="removeItem(index)"
                />
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </VuePerfectScrollbar>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import {
  BCard,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormDatalist,
  // BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BFormDatalist,
    // BFormDatepicker,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      create: false,
      showCalls: true,
      options: ["Outbound", "Inbound"],
      checked: false,
      counter: 6,
      showEditForm: false,
      calls: [
        {
          subject: "Subject-1",
          callType: "Inbound-1",
          duration: "00:35:20-1",
          assignee: "Super Admin-1",
        },
        {
          subject: "Subject-2",
          callType: "Outbound-2",
          duration: "00:35:20-2",
          assignee: "Super Admin-2",
        },
        {
          subject: "Subject-3",
          callType: "Inbound-3",
          duration: "00:35:20-3",
          assignee: "Super Admin-3",
        },
        {
          subject: "Subject-4",
          callType: "Inbound-4",
          duration: "00:35:20-4",
          assignee: "Super Admin-4",
        },
        {
          subject: "Subject-5",
          callType: "Outbound-5",
          duration: "00:35:20-5",
          assignee: "Super Admin-5",
        },
        {
          subject: "Subject-6",
          callType: "Inbound-6",
          duration: "00:35:20-6",
          assignee: "Super Admin-6",
        },
      ],
      subject: "",
      callType: "",
      duration: "",
      assignee: "",
      id: Number,
      settings: {
        maxScrollbarLength: 60,
      },
    };
  },
  methods: {
    addCall() {
      const conditionsArray = [
        this.subject !== "",
        this.callType !== "",
        this.assignee !== "",
        this.duration !== "",
      ];
      if (!conditionsArray.includes(false)) {
        this.calls.push({
          subject: this.subject,
          callType: this.callType,
          duration: this.duration,
          assignee: this.assignee,
        });

        this.clearFields();
        this.create = false;
        // eslint-disable-next-line no-plusplus
        this.counter++;
        this.showCalls = true;
      }
    },
    removeItem(index) {
      this.calls.splice(index, 1);
      // eslint-disable-next-line no-plusplus
      this.counter--;
    },
    getItemData(index) {
      // eslint-disable-next-line no-var
      this.subject = this.calls[index].subject;
      this.callType = this.calls[index].callType;
      this.duration = this.calls[index].duration;
      this.assignee = this.calls[index].assignee;
      this.id = index;
    },

    editCall() {
      const conditionsArray = [
        this.subject !== "",
        this.callType !== "",
        this.assignee !== "",
        this.duration !== "",
      ];
      if (!conditionsArray.includes(false)) {
        this.calls.push({
          subject: this.subject,
          callType: this.callType,
          duration: this.duration,
          assignee: this.assignee,
        });

        this.calls.splice(this.id, 1, {
          subject: this.subject,
          callType: this.callType,
          duration: this.duration,
          assignee: this.assignee,
        });

        this.clearFields();
        this.showEditForm = false;
        this.showCalls = true;
      }
    },
    clearFields() {
      this.subject = "";
      this.callType = "";
      this.duration = "";
      this.assignee = "";
    },
    scrollHandle() {},
  },
};
</script>

<style>
.reset-calls {
  transform: translateY(-10px);
}
</style>

<style scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 125px;
  height: 250px;
}
</style>
